import { useFormikContext } from "formik";
import { amrPipelineDetailedActions } from "../../../../../actions";
import { SORTING_TYPE, constants } from "../../../../../constants";
import { useAppDispatch } from "../../../../../effects/useAppDispatch";
import IconSVG from "../../../../../styles/svg-icons";
import { dealRegularDocumentList } from "../../../../../types/amr-pipeline/enums/DocumentType";
import { AmrDocument } from "../../../../../types/amr-pipeline/models/AmrDocument";
import DownloadDocument from "../../../../amrPipeline/common/documents/DownloadDocument";
import { FilesUploaderChildProps } from "../../../../amrPipeline/common/documents/FileUploader";
import { EmptySectionContent } from "../../../../amrPipeline/common/section/EmptySectionContent";
import { Table } from "../../../../bidding/common/table";
import { ColumnBuilder } from "../../../../bidding/common/table/columns/column-builder/ColumnBuilder";
import { SelectField } from "../../../../common/form-fields/SelectField";
import { DateField } from "../../../../common/form-fields/DateField";
import { CheckboxField } from "../../../../common/form-fields/CheckboxField";
import { FileIcon, ProgressCircle } from "../../../../controls";
import { DocumentColumn } from "../DocumentsList";
import { DocumentsForm } from "./EditDocuments";

interface Props extends FilesUploaderChildProps {
    documentKey: string;
    withTypeColumn?: boolean;
    withPublicColumn?: boolean;
}

export function EditDocumentsList({
    referenceName,
    documents,
    progress,
    handleDeleteFile,
    documentKey,
    withTypeColumn,
    withPublicColumn = false,
}: Props) {
    const dispatch = useAppDispatch();
    const { values } = useFormikContext<DocumentsForm>();
    const currentList = values[documentKey as keyof DocumentsForm];

    const getFormikIndex = (documentReferenceName: string) =>
        currentList.findIndex((l) => l.referenceName === documentReferenceName);

    const downloadDocument = (documentToDownload: AmrDocument) => {
        if (referenceName) {
            dispatch(
                amrPipelineDetailedActions.downloadDealDocument(
                    referenceName,
                    documentToDownload.referenceName,
                    documentToDownload.name
                )
            );
        }
    };

    const getTableColumns = () => {
        const documentsColumnDefinitions: DocumentColumn[] = [
            {
                columnKey: "name",
                renderColumnHeaderContent: () => "Name",
                headerClassName: "data-list-cell-xl-flexible",
                bodyClassName: "data-list-cell-xl-flexible",
                renderColumnContent: (document) => (
                    <DownloadDocument
                        document={document}
                        onClick={() => downloadDocument(document)}
                    >
                        <FileIcon filename={document.name} />
                        {document.name}
                    </DownloadDocument>
                ),
                sortingField: "name",
                sortingType: SORTING_TYPE.string,
            },
            {
                columnKey: "progress",
                renderColumnContent: (document) =>
                    document.referenceName ? null : (
                        <ProgressCircle progress={progress[document.name]} />
                    ),
                renderColumnHeaderContent: () => "",
                headerClassName: "data-list-cell-xxs",
                bodyClassName: "data-list-cell-xxs",
            },
            ...(withTypeColumn
                ? [
                    {
                        columnKey: "documentType",
                        renderColumnHeaderContent: () => "Type",
                        headerClassName: "data-list-cell-lg-02",
                        bodyClassName: "data-list-cell-lg-02",
                        renderColumnContent: (document) => (
                            <SelectField
                                name={`${documentKey}.${getFormikIndex(
                                    document.referenceName
                                )}.documentType`}
                                values={dealRegularDocumentList}
                                placeholder="Select Document Type"
                                optionLabelProp="text"
                                optionKeyProp="value"
                                className="form-control-sm"
                            />
                        ),
                    } as DocumentColumn,
                ]
                : []),
            {
                columnKey: "executionDate",
                renderColumnHeaderContent: () => "Date",
                headerClassName: "data-list-cell-lg",
                bodyClassName: "data-list-cell-lg",
                renderColumnContent: (document) => (
                    <DateField
                        withCustomView
                        dateFormat={constants.dateFormat}
                        maximumDate={new Date()}
                        name={`${documentKey}.${getFormikIndex(
                            document.referenceName
                        )}.executionDate`}
                    />
                ),
            },
            ...(withPublicColumn
                ? [
                    {
                        columnKey: 'isPublic',
                        renderColumnHeaderContent: () => 'Public',
                        headerClassName: 'data-list-cell-xxs-02 text-center',
                        bodyClassName: 'data-list-cell-xxs-02 text-center',
                        renderColumnContent: document => (
                            <CheckboxField
                                name={`${documentKey}.${getFormikIndex(document.referenceName)}.isPublic`}
                            />
                        ),
                    } as DocumentColumn,
                ]
                : []),
            {
                columnKey: "downloadButton",
                renderColumnHeaderContent: () => "",
                headerClassName: "data-list-cell-xxs",
                bodyClassName: "data-list-cell-xxs",
                renderColumnContent: (document) => (
                    <DownloadDocument
                        document={document}
                        onClick={() => downloadDocument(document)}
                    >
                        <IconSVG
                            name="downloadTemplate"
                            width={16}
                            height={16}
                        />
                    </DownloadDocument>
                ),
            },
            {
                columnKey: "delete-action",
                renderColumnContent: ({ referenceName }) => (
                    <button
                        className="btn-link btn-danger"
                        onClick={handleDeleteFile(referenceName)}
                    >
                        <IconSVG name="basket" width={16} height={16} />
                    </button>
                ),
                renderColumnHeaderContent: () => "",
                headerClassName: "data-list-cell-xxs",
                bodyClassName: "data-list-cell-xxs",
            },
        ];

        return documentsColumnDefinitions.map((c) => {
            return new ColumnBuilder(c);
        });
    };

    return (
        <div className="component-file-upload-list">
            {documents.length ? (
                <Table
                    columns={getTableColumns()}
                    dataItems={documents}
                    className="component-file-upload-list data-list-striped"
                    createRowCustomKey={(document: AmrDocument) =>
                        document.referenceName
                    }
                />
            ) : (
                <EmptySectionContent text="There are no documents yet" />
            )}
        </div>
    );
}

